import { Controller } from "@hotwired/stimulus"
import { Grid } from "gridjs"

const MUTATION_OBSERVER_CONFIG = { attributes: false, childList: true, subtree: true }
const DEFAULT_PAGINATION_LIMIT = "10"
const PAGINATION_LIMITS = ["10", "25", "100"]

export default class extends Controller {
  static targets = ["table", "paginationLimit", "paginationLimitOption"]
  static values = { headers: Object, rows: Object }
  static classes = ["paginationLimitActive"]

  initialize() {
    this.headers = JSON.parse(this.element.dataset.headers)
    this.rows = JSON.parse(this.element.dataset.rows)
  }

  connect() {
    let grid = new Grid({
      columns: this.columns(),
      data: this.rows,
      fixedHeader: true,
      className: this.customClasses(),
      ...this.element.sharedTable.defaultTableOptions()
    })
    this.element["grid"] = grid
    this.addObserversToTable()

    grid.render(this.tableTarget)
  }

  updatePaginationLimit(e) {
    let grid = this.element.grid
    let paginationLimit = e.target.dataset.limit

    this.paginationLimitOptionTargets.forEach(option => option.classList.remove(this.paginationLimitActiveClass))

    if (PAGINATION_LIMITS.includes(paginationLimit)) {
      e.target.classList.add(this.paginationLimitActiveClass)
    } else {
      paginationLimit = DEFAULT_PAGINATION_LIMIT
      this.paginationLimitOptionTarget.classList.add(this.paginationLimitActiveClass)
    }

    grid.updateConfig({ pagination: { limit: paginationLimit }})
    grid.forceRender()
  }

  customClasses() {
    return {}
  }

  addObserversToTable() {
    let paginationLimit = this.paginationLimitTarget

    const observer = new MutationObserver(function(mutationsList, _observer) {
      mutationsList.forEach(mutation => {
        mutation.addedNodes.forEach(n => {
          if (n.classList.contains("gridjs-summary")) {
            n.after(paginationLimit)
            paginationLimit.classList.remove("hidden")
          }
        })
      })
    })

    observer.observe(this.tableTarget, MUTATION_OBSERVER_CONFIG)
  }
}
