import dataTableController from "./data_table_controller";

export default class extends dataTableController {
  columns() {
    return this.headers.map(headerString => (
      {
        name: headerString,
        sort: this.sorter(headerString),
        attributes: this.element.sharedTable.setAttributes
      }
    ))
  }

  sorter(column) {
    let moneyCols = ["Paid", "Due"]
    let dateCols  = ["Date"]

    if (moneyCols.some(string => column.includes(string))) {
      return this.element.sharedTable.intSorter()
    } else if (dateCols.some(string => column.includes(string))) {
      return this.element.sharedTable.dateSorter()
    } else {
      return this.defaultSorter()
    }
  }

  defaultSorter() {
    return {
      compare: (a, b) => {
        if (a > b) {
          return 1;
        } else if (b > a) {
          return -1;
        } else {
          return 0;
        }
      }
    }
  }

  customClasses() {
    return { table: "max-w-screen-xl" }
  }
}
