import { Controller } from "@hotwired/stimulus"

const WINDOW_SIZE_MD = "768"
const WINDOW_SIZE_XL = "1280"

export default class extends Controller {
  static targets = ["link"]
  static values = {
    sizeSm: { type: String, default: "312px" },
    sizeMd: { type: String, default: "488px" },
    sizeXl: { type: String, default: "488px" },
    grouped: Boolean
  }

  connect() {
    $(this.linkTargets).colorbox({
      inline: true,
      rel: this.groupedValue ? $(this.linkTargets).attr("class") : "nofollow",
      transition: "none",
      href: function() {
        return $(this).data("href")
      },
      onComplete: this.resizeColorbox.bind(this)
    })
  }

  disconnect() {
    $.colorbox.remove()
  }

  resizeColorbox() {
    if (this.isClosed()) { return }

    let modalSizeSM = this.sizeSmValue,
        modalSizeMD = this.sizeMdValue,
        modalSizeXl = this.sizeXlValue,
        windowWidth = window.innerWidth;

    if (windowWidth < WINDOW_SIZE_MD) {
      $.colorbox.resize({ width: modalSizeSM })
    } else if (windowWidth >= WINDOW_SIZE_MD && windowWidth < WINDOW_SIZE_XL ) {
      $.colorbox.resize({ width: modalSizeMD })
    } else if (windowWidth >= WINDOW_SIZE_XL) {
      $.colorbox.resize({ width: modalSizeXl })
    }
  }

  isClosed() {
    return $("#colorbox").css("display") === "none"
  }
}
