import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "recaptcha", "formContainer", "confirmationMessage", "afterSubmissionContent"]
  static classes = ["fieldError"]

  connect() {
    this.input = document.createElement("input")
    let input = this.input

    input.type = "hidden"
    input.name = "form_submission[page_submitted_on]"
    input.value = location.href
    this.formTarget.appendChild(input)
    this.formId = this.formTarget.action.match(/\/forms\/(\d+)\/submissions/)[1]
  }

  disconnect() {
    this.input.remove()
  }

  redirectToDownload(event) {
    this.sendEventToTagManager()

    const [{ after_submission_content }, status, xhr] = event.detail

    window.open(after_submission_content, "_blank").focus()
    location.reload()
  }

  successAction() {
    this.sendEventToTagManager()
    this.dispatch("success")
  }

  showConfirmationMessage(event) {
    this.sendEventToTagManager()

    let afterSubmissionContent = event.detail[0].after_submission_content

    this.formContainerTarget.classList.add("hidden")

    if (afterSubmissionContent && this.hasAfterSubmissionContentTarget) {
      this.afterSubmissionContentTarget.classList.add("block")
      return this.afterSubmissionContentTarget.innerHTML = afterSubmissionContent
    }

    this.confirmationMessageTarget.classList.add("block")
    this.confirmationMessageTarget.classList.remove("hidden")
  }

  showErrors(event) {
    const [{ errors }, status, xhr] = event.detail,
          { clearErrors, recaptchaTarget, showErrorMessage, hasFieldErrorClass, addErrorClass } = this

    clearErrors()

    for (var key in errors) {
      if (key === "base") {
        if (errors[key][0] === "require_recaptcha") {
          recaptchaTarget.classList.remove("hidden")
        }
      } else {
        showErrorMessage(key, errors[key].join(", "))

        if (hasFieldErrorClass) { addErrorClass(key) }
      }
    }
  }

  sendEventToTagManager() {
    dataLayer.push({
      "form_id": this.formId,
      "event": "daisybill__form_submission_success"
    })
  }

  clearErrors=() => {
    this.formTarget.querySelectorAll("[data-error-field]").forEach((el) => el.innerText = null)

    if (this.hasFieldErrorClass) {
      this.formTarget.querySelectorAll("[data-field]").forEach((el) => el.classList.remove(this.fieldErrorClass))
    }
  }

  showErrorMessage=(field, message) => {
    this.formTarget.querySelector(`[data-error-field='${field}']`).innerText = message
  }

  addErrorClass=(field) => {
    this.formTarget.querySelector(`[data-field='${field}']`).classList.add(this.fieldErrorClass)
  }
}
