import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    message: String
  }

  connect() {
    throw new Error("This is a test error from JS: " + this.messageValue)
  }

  disconnect() {
  }
}
