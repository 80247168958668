import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["title", "button"]
  static values = {
    url: String,
    message: String,
  }

  connect() {
    this.boundVote = this.vote.bind(this)
    this.buttonTargets.forEach(element => {
      element.addEventListener("click", this.boundVote)
    })
  }

  disconnect() {
    this.removeClickEvent()
  }

  vote(e) {
    let formData = new FormData()

    let csrfToken = document.querySelector('meta[name="csrf-token"]')
    if (csrfToken) {
      formData.append("authenticity_token", csrfToken.content)
    }
    formData.append("article_vote[vote_type]", e.target.dataset.value)

    fetch(this.urlValue, { method: "POST", body: formData }).
      then((res) => {
        if (res.ok) {
          this.titleTarget.textContent = this.messageValue
          this.removeClickEvent()
        }
      })
  }

  removeClickEvent() {
    const { buttonTargets, boundVote } = this

    buttonTargets.forEach(element => {
      element.removeEventListener("click", boundVote)
    })
  }
}
