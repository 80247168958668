import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["collapse", "toggler"]
  static classes = ["collapsed", "closed", "opened"]

  toggle() {
    const collapsed = this.collapseTarget.classList.toggle(this.collapsedClass)

    if (this.hasClosedClass) {
      this.togglerTarget.classList.toggle(this.closedClass, !collapsed)
    }
    this.togglerTarget.classList.toggle(this.openedClass, collapsed)
  }
}
