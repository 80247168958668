import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["selected"]

  connect() {
    this.appendReferrer()
  }

  activateLink(event) {
    event.preventDefault()

    let selectedClass = this.selectedClass

    document.querySelectorAll("." + selectedClass).forEach(el => {
      el.classList.remove(selectedClass)
    })

    this.element.classList.toggle(selectedClass)
  }

  appendReferrer() {
    if (document.getElementsByClassName("js-embedded-articles").length === 0) { return }

    this.element.setAttribute("href", this.element.href + "&referrer=" + document.referrer)
  }
}
