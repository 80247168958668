export const initSentry = (Sentry) => {
  var sentryEnv = window.sentryConfig.environment;
  Sentry.init({
    dsn: window.sentryConfig.dsn,
    environment: sentryEnv,
    release: document.querySelector('meta[name="kb-release-sha"]')?.getAttribute('content'),
    enabled: ['production', 'staging', 'review'].includes(sentryEnv),
    integrations: [
      Sentry.replayIntegration(),
      ...(Sentry.thirdPartyErrorFilterIntegration ? [
        Sentry.thirdPartyErrorFilterIntegration({
          filterKeys: ['daisybill/knowledgeable'],
          behavior: 'drop-error-if-contains-third-party-frames'
        })
      ] : [])
    ]
  });
};
