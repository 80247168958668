import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]
  static classes = ["selected"]
  static values = {
    allowClear: Boolean,
    placeholder: String
  }

  connect() {
    const { inputTarget, allowClearValue, placeholderValue, element, toggleSelectedClass, dispatchSelectEvent } = this

    if (!inputTarget.dataset.select2Id) {
      $(inputTarget).select2({
        dropdownParent: element,
        allowClear: allowClearValue,
        placeholder: placeholderValue,
        width: "resolved",
        escapeMarkup: (markup) => { return markup }
      })
    }

    toggleSelectedClass()

    $(inputTarget).on("change.select2", () => dispatchSelectEvent("change"))
    $(inputTarget).on("select2:open", () => dispatchSelectEvent("open"))
    $(inputTarget).on("select2:select", () => dispatchSelectEvent("select"))
    $(inputTarget).on("select2:unselect", () => dispatchSelectEvent("unselect"))
  }

  toggleSelectedClass= () => {
    this.element.classList.toggle(this.selectedClass, !!this.inputTarget.value)
  }

  redirect() {
    // TODO: add back turbolinks
    // Turbolinks.visit(this.inputTarget.value)
    window.location.href = this.inputTarget.value
  }

  dispatchSelectEvent = (event) => {
    this.dispatch(event)
  }
}
