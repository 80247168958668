import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  next() { $.colorbox.next() }
  prev() { $.colorbox.prev() }

  close(e) {
    e.preventDefault()
    $.colorbox.close()
  }
}
