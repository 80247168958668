import { html } from "gridjs";
import dataTableController from "./data_table_controller";

export default class extends dataTableController {

  columns() {
    return [
      { name: this.headers.id },
      {
        name: this.headers.app_name,
        attributes: this.element.sharedTable.setAttributes
      },
      {
        name: this.headers.app_type,
        attributes: this.element.sharedTable.setAttributes
      },
      { name: this.headers.name },
      {
        name: this.headers.status,
        formatter: (cell) => html(cell)
      },
      {
        name: this.headers.approval_date,
        sort: this.element.sharedTable.dateSorter(),
        attributes: this.element.sharedTable.setAttributes
      },
      {
        name: this.headers.website,
        sort: false,
        formatter: (cell) => html(cell),
        attributes: this.element.sharedTable.setAttributes
      }
    ]
  }
}
