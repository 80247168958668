import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["state"]
  static values = {
    stateUrl: { type: String, default: "/helpful-resource-topics/" }
  }

  initialize() {
    this.boundRedirectEvent = this.redirectToState.bind(this);
  }

  connect() {
    this.stateTargets.forEach((el) => el.addEventListener("click", this.boundRedirectEvent) )
  }

  disconnect() {
    this.stateTargets.forEach((el) => el.removeEventListener("click", this.boundRedirectEvent) )
  }

  redirectToState(event) {
    // Turbolinks.visit(this.stateUrlValue + event.target.dataset.state)
    window.location.href = this.stateUrlValue + event.target.dataset.state
  }
}
