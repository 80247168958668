import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["imageWrapper", "tableWrapper"]

  connect() {
    let arcticleImages = this.element.querySelectorAll("img"),
        arcticleTables = this.element.querySelectorAll("table");

    arcticleImages.forEach((image) => image.parentElement.classList.add(this.imageWrapperClass));

    arcticleTables.forEach((table) => {
      let wrapper = document.createElement("div");

      wrapper.classList.add(this.tableWrapperClass);
      this.wrap(table, wrapper);
    });
  }

  wrap(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
  }
}
