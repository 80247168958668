import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["leftGradient", "rightGradient", "scrollContainer"]
  static classes = ["hideGradient"]

  connect() {
    this.leftGradientTarget.classList.add(this.hideGradientClass)
  }

  reviewGradientVisibility() {
    const { leftGradientTarget, rightGradientTarget, scrollContainerTarget, hideGradientClass, endScroll } = this

    rightGradientTarget.classList.toggle(hideGradientClass, endScroll)
    leftGradientTarget.classList.toggle(hideGradientClass, scrollContainerTarget.scrollLeft == 0)
  }

  get endScroll() {
    const { scrollContainerTarget } = this
    return (scrollContainerTarget.scrollWidth - scrollContainerTarget.scrollLeft) == scrollContainerTarget.clientWidth
  }
}
