import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["formContainer", "field", "form", "successText", "cancel", "recaptcha"]
  static classes = ["closed", "hide"]

  successAction() {
    this.formContainerTarget.classList.add(this.hideClass)
    this.successTextTarget.classList.remove(this.hideClass)
  }

  showForm() {
    this.fieldTarget.classList.remove(this.closedClass)
    this.formTarget.classList.remove(this.hideClass)
  }

  hideForm() {
    this.fieldTarget.classList.add(this.closedClass)
    this.formTarget.classList.add(this.hideClass)
  }

  errorsAction(event) {
    const [{ errors }, status, xhr] = event.detail

    this.clearErrors()

    if (errors === "require_recaptcha") {
      this.recaptchaTarget.classList.remove("hidden")
    } else {
      for (var key in errors) {
        this.showErrorMessage(key, errors[key].join(", "))
      }
    }
  }

  clearErrors=() => {
    this.formContainerTarget.querySelectorAll("[data-error-field]").forEach((el) => el.innerText = null)
  }

  showErrorMessage=(field, message) => {
    this.formContainerTarget.querySelector(`[data-error-field='${field}']`).innerText = message
  }
}
