import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "select"]
  static classes = ["opened"]

  connect() {
    let arcticleDropdown = this

    document.addEventListener("click", function(event) {
      let { menuTarget, selectTarget, openedClass } = arcticleDropdown,
          isClickInside = menuTarget.contains(event.target) || selectTarget.contains(event.target)

      if (!isClickInside) {
        arcticleDropdown.hideMenu()
      }
    })
  }

  toggleMenu() {
    let { menuTarget, selectTarget, openedClass } = this

    menuTarget.classList.toggle("block")
    selectTarget.classList.toggle(openedClass)
  }

  hideMenu() {
    let { menuTarget, selectTarget, openedClass } = this

    menuTarget.classList.remove("block")
    selectTarget.classList.remove(openedClass)
  }
}
