/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import * as Sentry from "@sentry/browser";
import { initSentry } from "./sentry"
initSentry(Sentry);

import "./jquery";

import Rails from "rails-ujs";
Rails.start();

import { Application } from "@hotwired/stimulus"

window.Stimulus = Application.start()
const application = Application.start()
import controllers from "./**/*_controller.js";
controllers.forEach((controller) => {
  application.register(controller.name.replace("controllers--", ""), controller.module.default);
});
// var Turbolinks = require("turbolinks");
// Turbolinks.start();

import select2 from "select2/dist/js/select2.full.js";
select2($);
import "jquery-colorbox";
import "./jquery-ui.js";

$(document).on("ready turbolinks:load", function() {
  Elemental.load(document);
  $(".js-select-two").select2();
});

$(() => {
  $(document).on("click", "a", function(e) {
    if ((e.currentTarget.href || '').startsWith("javascript:")) {
      e.preventDefault();
    }
  });
});

document.addEventListener("turbolinks:request-start", function(event) {
  var xhr = event.data.xhr;
  xhr.setRequestHeader("X_TURBOLINKS_NONCE", $("meta[name='csp-nonce']").prop('content'));
});

document.addEventListener('turbolinks:before-cache', function() {
  $('script[nonce]').each(function(index, element) {
    $(element).attr('nonce', element.nonce);
  });
});
