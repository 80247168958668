import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image"]
  static values = {
    mediaUrl: String,
    playerColor: { type: String, default: "855BD9" }, //purple-600
    videoWidth: { type: Number, default: 768 },
    autoPlay: { type: Boolean, default: true }
  }

  connect() {
    this.loadMedia()
  }

  loadMedia() {
    const url = `https://fast.wistia.net/oembed?url=${this.mediaUrlValue}?videoWidth=${this.videoWidthValue}&playerColor=${this.playerColorValue}&autoPlay=${this.autoPlayValue}&videoFoam=true`

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        this.showImage(data)
        this.videoHtml = data.html
      })
  }

  showImage(data) {
    const thumbnailUrl = data.thumbnail_url + `&image_play_button=true&image_play_button_color=${this.playerColorValue}`

    this.imageTarget.setAttribute("src", thumbnailUrl)
  }

  renderVideo() {
    this.element.innerHTML = this.videoHtml
  }
}
