import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    fullContent: String
  }

  showMore() {
    this.element.textContent = this.fullContentValue;
  }
}
