import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element["sharedTable"] = this;
  }

  setAttributes(cell, _row, column) {
    if (cell) {
      return {
        "data-title": column.name
      };
    }
  }

  search(e) {
    let input = document.querySelector(".gridjs-search-input")
    input.value = e.target.value

    let event = new Event("input", { bubbles: true, cancelable: true });
    input.dispatchEvent(event);
  }

  dateSorter() {
    return {
      compare: (a, b) => {
        if (Date.parse(a) > Date.parse(b)) {
          return 1;
        } else if (Date.parse(b) > Date.parse(a)) {
          return -1;
        } else {
          return 0;
        }
      }
    }
  }

  intSorter() {
    return {
      compare: (a, b) => {
        const intParser = (x) => parseInt((String(x).replace(/\D/g, "") || 0), 10)

        if (intParser(a) > intParser(b)) {
          return 1;
        } else if (intParser(b) > intParser(a)) {
          return -1;
        } else {
          return 0;
        }
      }
    }
  }

  defaultTableOptions() {
    return {
      sort: true,
      search: true,
      pagination: true,
      language: {
        pagination: {
          previous: " ",
          next: " ",
          showing: "Viewing",
          results: " "
        }
      }
    }
  }
}
