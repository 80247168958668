import { Controller } from "@hotwired/stimulus"

const LOGO_SHOW_POSITION = 15

export default class extends Controller {
  static classes = ["smallLogo", "background", "buttonShow", "toggle"]
  static targets = ["logo", "button"]
  static outlets = ["position"]

  connect() {
    this.switchLogoAndButton()
  }

  switchLogoAndButton() {
    let showLogo = window.scrollY > LOGO_SHOW_POSITION
    let showButton = this.positionOutlet.position.top <= 0

    this.logoTarget.classList.toggle(this.smallLogoClass, showLogo)
    this.element.classList.toggle(this.backgroundClass, showLogo)
    this.buttonTargets.forEach(btn => btn.classList.toggle(this.buttonShowClass, showButton))
  }

  toggle() {
    this.element.classList.toggle(this.toggleClass)
  }
}
