import { Controller } from "@hotwired/stimulus"

const NAVBAR_CLASS = "sticky-navbar--showed"

export default class extends Controller {
  static targets = ["stickyNavbar"]
  static values = {
    stickImmediately: Boolean
  }

  connect() {
    this.stopStickyNavbar = false
    this.stickyNavbarDisplay()
  }

  stickyNavbarDisplay() {
    if (this.stopStickyNavbar) {
      return
    }

    const { element, stickyNavbarTarget, stickImmediatelyValue } = this

    if (this.stickyNavbarShow) {
      stickyNavbarTarget.classList.add(NAVBAR_CLASS)

      if (stickImmediatelyValue) {
        element.parentElement.style.height = `${stickyNavbarTarget.offsetHeight}px`
      }
    } else {
      stickyNavbarTarget.classList.remove(NAVBAR_CLASS)
    }
  }

  hideStickyNavbar() {
    this.stickyNavbarTarget.classList.remove(NAVBAR_CLASS)
    this.stopStickyNavbar = true
  }

  get navbarWrapperHeight() {
    if (this.stickImmediatelyValue) {
      return 0
    } else {
      return this.element.offsetHeight
    }
  }

  get stickyNavbarShow() {
    return window.scrollY > (this.element.offsetTop + this.navbarWrapperHeight)
  }
}
