import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slider", "toggler"]
  static classes = ["togglerClosed", "togglerOpened"]

  toggle() {
    const { sliderTarget, togglerTarget, togglerOpenedClass, hasTogglerClosedClass, sliderClosed } = this

    sliderTarget.style.height = sliderClosed ? `${sliderTarget.scrollHeight}px` : "0px"

    if (hasTogglerClosedClass) {
      togglerTarget.classList.toggle(this.togglerClosedClass, !sliderClosed)
    }
    togglerTarget.classList.toggle(togglerOpenedClass, sliderClosed)
  }

  get sliderClosed() {
    return this.sliderTarget.clientHeight === 0
  }
}
