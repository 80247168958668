import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]
  static classes = ["highlighted"]

  fieldTargetConnected(target) {
    this.toggleHighlight(!!target.value)
  }

  checkHighlight(event) {
    this.toggleHighlight(!!event.target.value)
  }

  toggleHighlight(state) {
    this.fieldTarget.classList.toggle(this.highlightedClass, state)
  }
}
