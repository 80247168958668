import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["shadow"]

  connect() {
    this.toggle()
  }

  toggle() {
    this.element.classList.toggle(this.shadowClass, window.scrollY > 0)
  }
}
