import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["border", "shadow", "transition", "fixed", "absolute"]
  static outlets = ["sticky-navbar"]

  connect() {
    this.previousScroll = window.scrollY
    this.toggle()
  }

  toggle() {
    if (!this.stickyNavbarOutlet.stickyNavbarShow) {
      this.resetHeaderState()
      this.previousScroll = window.scrollY
      return
    }

    if (window.scrollY < this.previousScroll) {
      this.stickyNavbarOutlet.hideStickyNavbar()
      this.showHeader()
    } else {
      this.stickyNavbarOutlet.stopStickyNavbar = false
      this.element.style.top = `-${this.element.clientHeight}px`

      if (this.element.classList.contains(this.fixedClass)) {
        this.removeDefaultState()
      }
    }

    this.previousScroll = window.scrollY
  }

  resetHeaderState() {
    this.element.classList.add(...[...this.borderClasses, this.absoluteClass])
    this.element.classList.remove(...[this.shadowClass, this.fixedClass])
    this.resetTopPosition()
  }

  removeDefaultState() {
    this.element.classList.remove(...[...this.borderClasses, this.absoluteClass, this.shadowClass])
  }

  showHeader() {
    this.removeDefaultState()
    this.element.classList.add(...[...this.transitionClasses, this.fixedClass, this.shadowClass])
    this.resetTopPosition()
  }

  resetTopPosition() {
    this.element.style.top = "0px"
  }
}
