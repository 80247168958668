import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "clear"]
  static classes = ["dropdown", "selected", "clearVisible"]
  static values = {
    placeholder: String,
    minimize: Boolean,
    applySelect2: Boolean
  }

  connect() {
    let input         = this.inputTarget,
        isMinimize    = this.minimizeValue,
        selectedClass = this.selectedClass

    $(input).on("select2:select",   function() { toggleSelectedClass(true) })
    $(input).on("select2:unselect", function() { toggleSelectedClass(false) })

    if (!$(input).data("select2Id") && this.applySelect2Value) {
      $(input).select2({
        dropdownCssClass: this.dropdownClass,
        placeholder: this.placeholderValue,
        width: "resolved"
      })
    }

    $(input).siblings(".select2").on("click", function() {
      $(input).select2("open")
    })

    $(input).on("select2:open", function(e) {
      if (isMinimize) {
        $(e.target).siblings(".search-input .select2").attr("data-expanded", true)
      }

      searchFields()[searchFields().length - 1].focus()
      resizeDropdown()
    })

    $(input).on("select2:close", function(e) {
      if (input.value === "") {
        $(e.target).siblings(".search-input .select2").attr("data-expanded", false)
      }
    })

    function searchFields() {
      return document.querySelectorAll(".select2-container--open .select2-search__field")
    }

    function resizeDropdown() {
      if ($(input).attr("type") !== "text" && $(input).data("select2").isOpen()) {
        let dropdown = $(searchFields()).parents(".select2-container")
        $(dropdown).css("width", $(input).siblings(".select2.select2-container").outerWidth())
      }
    }

    function toggleSelectedClass(state) {
      let $select2Container = $(input).siblings(".select2-container")

      if ($select2Container.length) {
        $select2Container.toggleClass(selectedClass, state)
      }
    }

    window.addEventListener("resize", resizeDropdown, true);
  }

  expandInput() {
    this.inputTarget.dataset.expanded = true
  }

  clearInput() {
    const input = this.inputTarget;
    input.value = ""
    input.focus()
    this.clearTarget.classList.remove(this.clearVisibleClass)
    if ($(input).autocomplete("instance") !== undefined) {
      $(input).autocomplete("search")
    }
  }

  shrinkInput() {
    if (this.inputTarget.value === "") {
      this.inputTarget.dataset.expanded = false
    }
  }

  updateClear() {
    if (this.hasClearTarget) {
      if (this.inputTarget.value === "") {
        this.clearTarget.classList.remove(this.clearVisibleClass)
      } else {
        this.clearTarget.classList.add(this.clearVisibleClass)
      }
    }
  }
}
