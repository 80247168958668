import { Controller } from "@hotwired/stimulus"

const WINDOW_SIZE_MD = "768"

export default class extends Controller {
  static targets = ["input", "select"]
  static values = {
    toggleSelects: Boolean
  }

  connect() {
    let selects         = this.selectTargets,
        input           = this.inputTarget,
        isToggleSelects = this.toggleSelectsValue

    input.addEventListener("focus", () => toggleSelects(true))
    input.addEventListener("blur",  () => toggleSelects(false))

    $(input).on("select2:open",   () => toggleSelects(true))
    $(input).on("select2:select", () => toggleSelects(false))
    $(input).on("select2:close",  () => toggleSelects(false))

    $(selects).on("select2:open", function() {
      if (isToggleable) {
        $(this).parent().attr("data-minimized", false)
      }
    })

    function isToggleable() {
      return (window.innerWidth < WINDOW_SIZE_MD) && isToggleSelects
    }

    function toggleSelects(state) {
      if (isToggleable()) {
        selects.forEach(element => element.parentElement.dataset.minimized = state)
      }
    }
  }
}
